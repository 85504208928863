/**
 * Styles targeting the COVID-19 Giving Page
 *
 */

/* =Hero Panel
----------------------------------------------------------*/

.hero-panel {
  position: relative;
  background: $wcm-red;
  overflow: hidden;
  @include breakpoint($md) {
    height: 350px;
  }
  .hero-panel__image {
    &:before {
      background-color: $wcm-dark-orange;
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .hero-panel__headline {
    font-size: 18px;
    font-family: $wcm-bold;
    line-height: 1.2;
    color: $wcm-white;
    text-align: center;
    text-shadow: 1px 2px 5px rgba(0,0,0,0.6);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 10;
    @include breakpoint($xs) {
      padding: 0 30px 0;
    }
    @include breakpoint($sm) {
      font-size: 30px;
      padding: 0 15% 10px;
    }
    @include breakpoint($md) {
      font-size: 36px;
      padding: 0 15% 10px;
    }
  }
}

//https://www.sitepoint.com/an-introduction-to-css-supports-rule-feature-queries/
@supports (mix-blend-mode: multiply) {
  .hero-panel__image:before {
    mix-blend-mode: multiply;
  }
}

// Fallback
@supports not(mix-blend-mode: multiply) {
  .hero-panel__image:before {
    opacity: 0.8;
  }
}

/* =Section Title
----------------------------------------------------------*/
.section__title {
  border-top: 5px solid #b31b1b;
  text-align: center;
  margin: 0 0 30px;
  span {
    display: inline-block;
    background: #b31b1b;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: -1px 0 0;
    padding: 5px 20px;
    letter-spacing: 2px;
  }
}

/* =Intro Panel
----------------------------------------------------------*/
.intro-panel {
  background: $wcm-bg-gray;
  text-align: center;
  padding: 60px 0;
  p {
    color: $wcm-dark-gray;
    font-family: $wcm-regular;
    font-size: 20px;
    line-height: 1.5;
    padding: 0 10%;
    strong {
      color: $wcm-red;
    }
  }
}

/* =Donate Now Panel
----------------------------------------------------------*/

.donate-now-panel {
  @include breakpoint($md) {
    display: flex;
  }
}

.row--card-callouts {
  overflow: hidden;
}

.card-callout {
  margin: 20px 0 20px;
  padding-bottom: 20px;
  @include breakpoint($md) {
    border: none;
    margin: 20px 0;
    padding-bottom: 0;
  }
}

.card-callout__wrapper {
  background: $wcm-bg-gray;
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}

.card-callout__text {
  padding: 20px 20px 0;
}

.card-callout__title {
  margin: 0 0 10px;
  text-align: center;
  a {
    color: $wcm-red;
    &:hover {
      color: $wcm-bright-orange;
      text-decoration: underline;
      &:before {
        text-decoration: none;
      }
    }
    &:after {
      content: '' !important;
      display: none !important;
    }
  }
  a span {
    display: block;
    position: relative;
    top: -40px;
    margin: 0 auto -40px;
  }
  a span:before {
    content: '\e802';
    width: 50px;
    height: 50px;
    color: $wcm-bright-orange;
    background: $wcm-bg-gray;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    @include fontello();
    line-height: 50px;
    display: inline-block;
    @include transition(background-color .25s);
  }
  a.external-link span:before {
    content: '' !important;
    background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 15px 15px;
    margin-left: 6px;
    position: relative;
    top: 2px;
  }
  a:hover span:before {
    text-decoration: none;
  }
}

.card-callout__description {
  font-size: 15px;
  text-align: center;
}

.card-callout__image {
  overflow: hidden;
}

.card-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
}

.card-callout__cta {
  font-size: 14px;
  a {
    border: none;
  }
}

.donate-now-panel__instructions {
  text-align: center;
  margin: 20px 0 30px;
}

/* =In-Kind Panel
----------------------------------------------------------*/

.in-kind-panel {
  margin: 0 0 30px;

  h3 {
    margin-top: 0;
  }
  .in-kind-panel__image {
    margin: 0 0 20px;
    @include breakpoint($md) {
      margin: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .in-kind-panel__text h3 {
    margin: 0 0 10px;
  }
}

/* =Resources Panel
----------------------------------------------------------*/

.covid-resources-panel {
  padding: 0 0 30px;
  background: $wcm-bg-gray;
}

/* =COVID Hotline
----------------------------------------------------------*/

.covid-hotline {
  text-align: center;
  padding: 30px 0;
  background: $wcm-red;
  color: $wcm-white;
  .covid-hotline__headline {
    font-size: 30px;
    font-family: $wcm-bold;
  }
  p {
    font-size: 20px;
  }
}

/* =Giving Tuesday webform
----------------------------------------------------------*/
.covid-19-callout-orange {
  a {
    color: $wcm-white;
    display: block;
    text-align: center;
    padding: 30px 0;
    background: $wcm-dark-orange;
    font-size: 30px;
    font-family: $wcm-bold;
    margin-bottom: 20px;
  }
}

.hero-panel-no-color {
  position: relative;
  background: $wcm-red;
  overflow: hidden;
  margin-bottom: 40px;

  @include breakpoint($md) {
    // height: 350px;
  }
  .hero-panel__image {
    &:before {
      background-color: $wcm-black;
      opacity: 0.3;
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .hero-panel__headline {
    font-size: 18px;
    font-family: $wcm-bold;
    line-height: 1.2;
    margin-top: 0;
    color: $wcm-white;
    text-align: center;
    text-shadow: 1px 2px 5px rgba(0,0,0,0.6);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 10;
    @include breakpoint($xs) {
      padding: 0 30px 0;
    }
    @include breakpoint( (max: 390px) ) {
      font-size: 15px;
    }
    @include breakpoint( (max: 440px) ) {
      padding: 0;
    }
    @include breakpoint($sm) {
      font-size: 30px;
      padding: 0 10% 10px;
    }
    @include breakpoint($md) {
      font-size: 36px;
      padding: 0 12% 10px;
    }
  }

  .btn-primary {
    margin-top: 10px;
    padding: 6px 40px;
    font-weight: bold;
    @include breakpoint($sm) {
      font-size: 20px;
      margin-top: 30px;
    }
    @include breakpoint($md) {
      margin-top: 40px;
    }
  }
}

.pane-webform-embed-panel-pane-1 {

  margin-bottom: 30px;
  margin-top: 40px;

  .col-sm-12 {
    margin-top: 20px;
    // border-top: 5px solid $wcm-red;
    margin-bottom: 20px;
    h2 {
      text-align: center;
    }
  }

  .disclaimer, .webform-component--disclaimer {
    font-style: italic;
  }

  .giving-messages-container {
    background: $wcm-bg-gray;
    border: 2px solid $wcm-bright-orange;
    padding: 25px;
    height: 730px;
    text-align: center;

    h3 {
      margin-top: 0;
    }
    h4 {
      padding-bottom: 20px;
      margin-top: 5px;
      margin-bottom: 20px;
      border-bottom: 1px solid $wcm-border-gray;
    }

    #giving-tuesday-messages {
      @include breakpoint( (max:390px) ) {
        height: 80%;
      }

      height: 85%;
      overflow-y: scroll;
      padding-bottom: 20px;
      word-break: break-word;

      &.highlight_response {
        .message_response:first-child {
          background-color: $wcm-dark-orange;
          color: $wcm-white;
        }
      }

      .message_response {
        margin-bottom: 20px;

        .message_text {
          font-weight: 700;
          font-style: italic;
        }
        .message_author {
          font-family: $wcm-regular;
        }
      }
    }
  }
}


