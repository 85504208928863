/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/* =============================================================================
   ~Navigation
   ========================================================================== */
#primary-nav .level-1 {
  width: 16.6%; }
  #primary-nav .level-1:last-child a:hover {
    border-bottom: none; }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1 {
      padding: 1em 16px; } }

.global-links__wcm {
  display: none !important; }

.global-links a {
  color: #666666 !important; }
  .global-links a:hover {
    color: #666666; }

.global-links > li > a:hover, .global-links > li > a:focus {
  color: #666666; }

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em; }
    .block-menu-block li.level-1:nth-child(2) {
      display: inline-block; }
    .block-menu-block li.level-1:nth-child(3) {
      display: inline-block; } }

@media screen and (min-width: 768px) {
  .footer-contact__contact-us a {
    padding: 20px 20px; } }

@media screen and (min-width: 992px) {
  .footer-contact__contact-us a {
    padding: 20px 48px; } }

@media screen and (min-width: 768px) {
  .footer-contact__social-links li {
    padding: 0 5px; } }

@media screen and (min-width: 992px) {
  .footer-contact__social-links li {
    padding: 0 10px; } }

/* =============================================================================
   ~Header
   ========================================================================== */
/*.brand__nav {
    background-color: inherit;

    .navbar-nav>li>a {
        line-height: 75px;
    }
}

.brand__logo a {
    display: block;
    background: url(../images/WCM-logo.png) no-repeat left;
    width: 210px;
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    @include breakpoint($sm) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    background-size: 210px;
}
.global-links a {
    color: $wcm-med-gray;
    &:hover {
        color: $wcm-med-gray;
    }
}
.global-links>li>a:hover, .global-links>li>a:focus {
    color: $wcm-med-gray;
}*/
/*.search-toggle span {
    border: 2px solid #fff;
    margin-top: 20px;
//    display: block;
//    width: 40px;
//    height: 40px;
//    border-radius: 50%;
//    cursor: pointer;
//    margin: 6px 0 6px 8px;

    &:hover {
        border: 2px solid $wcm-dark-orange;
    }

    .cta-icon-search {
        color: $wcm-dark-orange;
        fill: $wcm-bright-orange;
    }
}
.search-toggle.open span {
    margin-top: 15px;
    border: 2px solid #fff;
    background: #fff;
    &:hover {
        .cta-icon-search {
            fill: $wcm-dark-orange;
        }
    }
}*/
#tagline {
  margin: 0 auto;
  text-align: center;
  display: none; }
  @media screen and (min-width: 768px) {
    #tagline {
      background: #b31b1b;
      color: #fff;
      font-size: 2.2rem;
      letter-spacing: .65em;
      padding: .4em 0;
      display: block;
      font-weight: normal; } }
  @media screen and (min-width: 992px) {
    #tagline {
      background: #b31b1b;
      color: #fff;
      font-size: 2.6rem;
      letter-spacing: .75em;
      padding: .6em 0;
      display: block;
      font-weight: normal; } }

#tagline a {
  color: #fff;
  text-decoration: none; }

#tagline-mobile {
  color: #fff;
  letter-spacing: .2em;
  padding: .3em 0;
  text-align: center;
  display: block;
  font-size: 1.4rem; }
  @media screen and (min-width: 768px) {
    #tagline-mobile {
      display: none; } }
  @media screen and (min-width: 992px) {
    #tagline-mobile {
      display: none; } }

#donate-to-weill {
  display: none;
  float: left;
  color: #b31b1b; }
  @media screen and (min-width: 768px) {
    #donate-to-weill {
      font-size: 1.4rem;
      margin-top: .3em;
      display: none; } }
  @media screen and (min-width: 992px) {
    #donate-to-weill {
      font-size: 1.6rem;
      margin-top: .2em;
      display: block; } }
  #donate-to-weill span {
    display: block;
    margin: .2em .4em 0 0;
    float: left; }

#donate-button-top {
  float: right;
  padding: .2em 1em .2em .8em;
  border-radius: 4px;
  text-transform: uppercase;
  background: #b31b1b;
  color: #fff; }
  @media screen and (min-width: 768px) {
    #donate-button-top {
      font-size: 1.3rem; } }
  @media screen and (min-width: 992px) {
    #donate-button-top {
      font-size: 1.4rem; } }
  #donate-button-top:hover {
    background: #fff;
    border: 1px solid #b31b1b;
    color: #b31b1b;
    cursor: pointer; }

/* =============================================================================
   ~Homepage
   ========================================================================== */
#home-campaign {
  margin: 0 1em;
  margin-bottom: 30px; }
  @media screen and (min-width: 768px) {
    #home-campaign {
      width: 660px;
      margin: 0 auto;
      margin-top: 1em;
      margin-bottom: 30px; } }
  @media screen and (min-width: 992px) {
    #home-campaign {
      width: 960px; } }

#home-campaign h3 {
  color: #b31b1b;
  text-transform: capitalize;
  font-size: 1.8rem;
  line-height: 24px; }
  @media screen and (min-width: 768px) {
    #home-campaign h3 {
      font-size: 3.0rem;
      text-align: center;
      line-height: 34px;
      font-weight: 400; } }
  @media screen and (min-width: 992px) {
    #home-campaign h3 {
      font-size: 4.0rem;
      text-align: center;
      line-height: 44px;
      font-weight: 400; } }

#home-campaign p {
  /*@include breakpoint($sm) {
		font-size: 1.8rem;
		line-height: 22px;
	}
	@include breakpoint($md) {
		font-size: 1.8rem;
		line-height: 22px;
	}*/ }

#homepage-donate {
  cursor: pointer;
  float: right;
  border-radius: 50%;
  width: 11.06em;
  height: 11.05em;
  border: .7em solid #fff;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.38);
  padding: 2.53em 1.68em 1.01em 1.47em;
  background: #cf4520;
  text-align: center;
  margin: 6% 3% 0 0; }
  @media screen and (min-width: 768px) {
    #homepage-donate {
      width: 15.2em;
      height: 15.2em;
      background: url("/sites/default/files/general/scope-heart.png") no-repeat center 28% #cf4520;
      border: .7em solid #fff;
      padding: 4.2em 2.25em 1.35em 1.96em;
      text-align: center;
      margin: 6% 3% 0 4%; } }
  @media screen and (min-width: 992px) {
    #homepage-donate {
      border: 1em solid #fff;
      margin: 5% 3% 10% 4%; } }

#homepage-donate:hover {
  /*@include breakpoint($md) {
			background: url("/sites/default/files/general/scope-heart.png") no-repeat center 28% #952626;
		}*/ }
  @media screen and (min-width: 768px) {
    #homepage-donate:hover {
      background: url("/sites/default/files/general/scope-heart.png") no-repeat center 28% #e7751d; } }

#homepage-donate h3 {
  color: #fff; }
  @media screen and (min-width: 768px) {
    #homepage-donate h3 {
      font-size: 2.4rem;
      line-height: 28px;
      padding-top: 2em; } }
  @media screen and (min-width: 992px) {
    #homepage-donate h3 {
      font-size: 2.4rem;
      line-height: 28px;
      padding-top: 2em; } }

.front blockquote {
  border: none; }

.short-quote {
  background-size: 100% 100%; }
  @media screen and (min-width: 768px) {
    .short-quote {
      margin: 0 auto;
      width: 660px; } }
  @media screen and (min-width: 992px) {
    .short-quote {
      margin: 0 auto;
      width: 960px; } }

.short-quote blockquote {
  margin: 0;
  padding: 1em 0 0 0; }

.short-quote blockquote h2 {
  font-weight: 400;
  margin: 0 1em;
  position: relative;
  color: #000000;
  text-align: center;
  line-height: 1.5em;
  font-size: 1.7rem; }
  @media screen and (min-width: 768px) {
    .short-quote blockquote h2 {
      font-size: 1.7rem;
      margin: 0; } }
  @media screen and (min-width: 992px) {
    .short-quote blockquote h2 {
      font-size: 2.2rem;
      margin: 0; } }
  .short-quote blockquote h2:before {
    content: "";
    background-size: 100%;
    position: absolute;
    background: url(../images/quote-small-left.jpg) no-repeat;
    width: 28px;
    height: 28px;
    margin-left: -28px;
    top: -5px; }
    @media screen and (min-width: 768px) {
      .short-quote blockquote h2:before {
        background: url(../images/quote-left.jpg) no-repeat;
        width: 55px;
        height: 55px;
        margin-left: -55px;
        top: -10px; } }

.short-quote p {
  font-size: 1.6rem;
  padding: 0 1em;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .short-quote p {
      font-size: 1.6rem;
      padding: 0; } }
  @media screen and (min-width: 992px) {
    .short-quote p {
      font-size: 1.8rem;
      padding: 0; } }

.short-quote span {
  color: #b31b1b;
  position: relative;
  display: block;
  margin-top: .5em; }
  @media screen and (min-width: 768px) {
    .short-quote span {
      font-size: 2.3rem;
      line-height: 1.3em; } }
  @media screen and (min-width: 992px) {
    .short-quote span {
      font-size: 2.8rem;
      line-height: 1.4em; } }
  .short-quote span:after {
    content: "";
    background-size: 100%;
    position: absolute;
    background: url(../images/quote-small-right.jpg) no-repeat;
    width: 28px;
    height: 28px;
    top: auto; }
    @media screen and (min-width: 321px) {
      .short-quote span:after {
        top: 25px; } }
    @media screen and (min-width: 768px) {
      .short-quote span:after {
        background: url(../images/quote-right.jpg) no-repeat;
        width: 55px;
        height: 55px; } }

.short-quote p.quote-action {
  display: block !important;
  font-size: 2.0rem;
  padding: 0.55em 2.5em;
  text-transform: uppercase;
  width: 36%;
  margin: 0 auto .75em auto; }

/* =============================================================================
   ~Interior
   ========================================================================== */
.page-priorityareas #main {
  max-width: none;
  margin: 0;
  padding: 0; }

.priorities-listing {
  margin: 0 1em; }
  @media screen and (min-width: 768px) {
    .priorities-listing {
      width: 660px;
      margin: 0 auto; } }
  @media screen and (min-width: 992px) {
    .priorities-listing {
      width: 960px;
      margin: 0 auto; } }

#priorities-listing-content .views-row,
.view-ways-to-give .views-row,
.view-leadership .views-row {
  border-bottom: 1px solid #dddddd;
  padding: 20px 0px 11px;
  /*@include breakpoint($sm) {
		border-bottom: 1px solid $wcm-border-gray;
        padding: 20px 0px 11px;
	}*/ }
  #priorities-listing-content .views-row:before, #priorities-listing-content .views-row:after,
  .view-ways-to-give .views-row:before,
  .view-ways-to-give .views-row:after,
  .view-leadership .views-row:before,
  .view-leadership .views-row:after {
    content: " ";
    display: table; }
  #priorities-listing-content .views-row:after,
  .view-ways-to-give .views-row:after,
  .view-leadership .views-row:after {
    clear: both; }
  #priorities-listing-content .views-row h2,
  .view-ways-to-give .views-row h2,
  .view-leadership .views-row h2 {
    margin-top: 0px; }

.view-priority-areas p {
  font-size: 1.8rem; }

.view-milestones .view-content {
  text-align: center; }
  @media screen and (min-width: 768px) {
    .view-milestones .view-content {
      text-align: left; } }
  @media screen and (min-width: 992px) {
    .view-milestones .view-content {
      text-align: left; } }

#priorities-listing-content .views-field-field-featured-image,
.view-ways-to-give .views-field-field-featured-image,
.view-leadership .views-field-field-featured-image,
.view-milestones img {
  text-align: center;
  margin-bottom: 0.5em;
  /*@include breakpoint($md) {
		margin-right: 1em;
		float: left;
		text-align:left;
	}*/ }
  @media screen and (min-width: 768px) {
    #priorities-listing-content .views-field-field-featured-image,
    .view-ways-to-give .views-field-field-featured-image,
    .view-leadership .views-field-field-featured-image,
    .view-milestones img {
      margin-right: 1em;
      margin-bottom: 0;
      float: left;
      text-align: left; } }

.view-milestones .milestonethumb-blank {
  display: none; }
  @media screen and (min-width: 768px) {
    .view-milestones .milestonethumb-blank {
      display: block; } }

.view-milestones img {
  width: 180px;
  border-radius: 0; }

#priorities-listing-content a > img,
.view-ways-to-give a > img,
.view-leadership a > img {
  border-radius: 0;
  border: 4px solid #ebebeb; }

#priorities-listing-content h2.field-content,
.view-milestones h2.field-content {
  font-weight: 400;
  margin-bottom: .4em;
  text-align: center;
  font-size: 2.4rem; }
  @media screen and (min-width: 768px) {
    #priorities-listing-content h2.field-content,
    .view-milestones h2.field-content {
      font-size: 2.8rem;
      text-align: left; } }
  @media screen and (min-width: 992px) {
    #priorities-listing-content h2.field-content,
    .view-milestones h2.field-content {
      font-size: 2.8rem;
      text-align: left; } }

.view-milestones .views-field-body {
  text-align: left;
  float: right; }

.view-milestones .field-content {
  font-size: 1.8rem; }

#panels-ipe-regionid-sidebar {
  margin-top: 2em; }
  @media screen and (min-width: 768px) {
    #panels-ipe-regionid-sidebar {
      margin-top: 0; } }
  @media screen and (min-width: 992px) {
    #panels-ipe-regionid-sidebar {
      margin-top: 0; } }

.pane-node-body iframe {
  max-width: 640px;
  width: 100%; }

.button {
  color: #fff;
  display: block;
  padding: .55em 2.5em .55em 3.5em;
  margin-bottom: .5em;
  text-align: center;
  cursor: pointer; }
  .button, .button a {
    color: #fff;
    text-decoration: none; }
  .button:hover {
    background: #00355f; }
  @media screen and (min-width: 768px) {
    .button {
      font-size: 1.8rem; } }
  @media screen and (min-width: 992px) {
    .button {
      font-size: 2.0rem; } }

@media screen and (min-width: 768px) {
  .panel-twocol #information-sidebar {
    font-size: 1.6rem; } }

.donate-now {
  background: url("/sites/default/files/general/scope-heart-sm.png") no-repeat 13% center #cf4520; }
  .donate-now:hover {
    background: url("/sites/default/files/general/scope-heart-sm.png") no-repeat 13% center #e7751d; }

.contact-us {
  background: url("/sites/default/files/general/mail-icon.png") no-repeat 13% center #cf4520; }
  .contact-us:hover {
    background: url("/sites/default/files/general/mail-icon.png") no-repeat 13% center #e7751d; }

figure.hero-image,
.field-name-field-thumbnail-image {
  position: relative; }

.hero-image img,
.field-name-field-thumbnail-image img {
  border: 1px solid #d8d2c9; }

.hero-image figcaption {
  line-height: 1.2em;
  margin-top: 0.7em;
  font-size: 1.4rem; }

.page-milestones h1 {
  margin: 20px 0; }

@media screen and (min-width: 768px) {
  .page-milestones .hero-image,
  .page-milestones .field-name-field-thumbnail-image {
    float: right; } }

@media screen and (min-width: 992px) {
  .page-milestones .hero-image,
  .page-milestones .field-name-field-thumbnail-image {
    float: right; } }

.page-milestones .medium {
  border-radius: 0;
  border: 1px solid #d8d2c9;
  margin: 0 0 1em; }
  @media screen and (min-width: 768px) {
    .page-milestones .medium {
      margin: 1.5em 0 2.5em; } }
  @media screen and (min-width: 992px) {
    .page-milestones .medium {
      margin: 1.5em 0 2.5em; } }

.view-milestones .views-row {
  border-bottom: 1px solid #dddddd;
  margin-top: 1em;
  padding-bottom: 1em; }

.page-milestones .views-row-first {
  margin-top: 0; }

.page-milestones .views-row-last {
  border-bottom: 0; }

@media screen and (min-width: 768px) {
  #panels-ipe-regionid-header {
    height: 300px;
    position: relative; } }

@media screen and (min-width: 992px) {
  #panels-ipe-regionid-header {
    height: 332px;
    position: relative; } }

#panels-ipe-paneid-87,
.pane-fpid-32 {
  margin: 0 1em; }
  @media screen and (min-width: 768px) {
    #panels-ipe-paneid-87,
    .pane-fpid-32 {
      width: 660px;
      margin: 0 auto; } }
  @media screen and (min-width: 992px) {
    #panels-ipe-paneid-87,
    .pane-fpid-32 {
      margin: 0 auto;
      width: 960px; } }

#panels-ipe-paneid-58 table,
#panels-ipe-paneid-58 th,
#panels-ipe-paneid-58 tr,
#panels-ipe-paneid-58 td {
  width: 100%;
  vertical-align: top; }

#panels-ipe-paneid-58 td {
  padding: .5em;
  width: 32%; }

.pane-bundle-table {
  font-size: 1.5rem; }
  .pane-bundle-table .pane-title {
    font-size: 1.8rem; }
  .pane-bundle-table tr.even {
    background: none; }
  .pane-bundle-table tr.odd {
    background: none; }
  .pane-bundle-table td {
    padding: .5em .25em; }

/* =============================================================================
   ~Components
   ========================================================================== */
/*.title-bar {
    font-family: $wcm-regular;
    display: block;
    font-size: 24px;
//    padding-top: 5px;
    @include breakpoint($sm) {
        font-size: 46px;
//        padding-top: 10px;
//        line-height: 1.8;
    }

    .site-title {
        display: block;
        padding-top: 5px;
        @include breakpoint($sm) {
            padding-top: 10px;
        }
    }
}*/
.contact-info .site-name {
  display: none; }

@media screen and (min-width: 992px) {
  .milestone-story-dates {
    font-size: 1.6rem; } }

.view-milestones h3.pane-title,
.view-milestones .panel-separator {
  display: none; }

.view-milestones h3 {
  margin: 0 0 .2em;
  font-weight: 400; }
  .view-milestones h3 a:after {
    font-size: 12px;
    color: #e7751d;
    content: '\e80D';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: none;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1.8em;
    margin-left: .2em;
    vertical-align: top;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

h2 a {
  color: #b31b1b; }
  h2 a:hover {
    color: #e7751d; }
  h2 a:after {
    font-size: 16px;
    color: #e7751d;
    content: '\e80D';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: none;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1.8em;
    margin-left: .2em;
    vertical-align: top;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

h3 a {
  color: #b31b1b; }
  h3 a:hover {
    color: #e7751d; }
  h3 a:after {
    font-size: 12px;
    color: #e7751d;
    content: '\e80D';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: none;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1.8em;
    margin-left: .2em;
    vertical-align: top;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.ctools-collapsible-processed .ctools-collapsible-handle h2 {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: #cf4520;
  border: 1px solid #ddd;
  border-width: 3px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600; }

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
  content: '-';
  width: 50px;
  display: block;
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  border-left: 3px solid #ddd; }

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
  content: '+'; }

.ctools-collapsible-container .ctools-toggle {
  display: none; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

.wcm-cta .wcm-cta__tile:nth-child(3) {
  width: 100%;
  border-width: 1px 0 0 0; }
  .wcm-cta .wcm-cta__tile:nth-child(3):hover {
    border-width: 1px; }

/**
 * Styles targeting the COVID-19 Giving Page
 *
 */
/* =Hero Panel
----------------------------------------------------------*/
.hero-panel {
  position: relative;
  background: #b31b1b;
  overflow: hidden; }
  @media screen and (min-width: 992px) {
    .hero-panel {
      height: 350px; } }
  .hero-panel .hero-panel__image:before {
    background-color: #cf4520;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .hero-panel .hero-panel__image img {
    width: 100%;
    height: auto; }
  .hero-panel .hero-panel__headline {
    font-size: 18px;
    font-family: "1898Sans-Bold", sans-serif;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 10; }
    @media screen and (max-width: 767px) {
      .hero-panel .hero-panel__headline {
        padding: 0 30px 0; } }
    @media screen and (min-width: 768px) {
      .hero-panel .hero-panel__headline {
        font-size: 30px;
        padding: 0 15% 10px; } }
    @media screen and (min-width: 992px) {
      .hero-panel .hero-panel__headline {
        font-size: 36px;
        padding: 0 15% 10px; } }

@supports (mix-blend-mode: multiply) {
  .hero-panel__image:before {
    mix-blend-mode: multiply; } }

@supports not (mix-blend-mode: multiply) {
  .hero-panel__image:before {
    opacity: 0.8; } }

/* =Section Title
----------------------------------------------------------*/
.section__title {
  border-top: 5px solid #b31b1b;
  text-align: center;
  margin: 0 0 30px; }
  .section__title span {
    display: inline-block;
    background: #b31b1b;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: -1px 0 0;
    padding: 5px 20px;
    letter-spacing: 2px; }

/* =Intro Panel
----------------------------------------------------------*/
.intro-panel {
  background: #f7f7f7;
  text-align: center;
  padding: 60px 0; }
  .intro-panel p {
    color: #555555;
    font-family: "1898Sans-Regular", sans-serif;
    font-size: 20px;
    line-height: 1.5;
    padding: 0 10%; }
    .intro-panel p strong {
      color: #b31b1b; }

/* =Donate Now Panel
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .donate-now-panel {
    display: flex; } }

.row--card-callouts {
  overflow: hidden; }

.card-callout {
  margin: 20px 0 20px;
  padding-bottom: 20px; }
  @media screen and (min-width: 992px) {
    .card-callout {
      border: none;
      margin: 20px 0;
      padding-bottom: 0; } }

.card-callout__wrapper {
  background: #f7f7f7;
  margin-bottom: -99999px;
  padding-bottom: 99999px; }

.card-callout__text {
  padding: 20px 20px 0; }

.card-callout__title {
  margin: 0 0 10px;
  text-align: center; }
  .card-callout__title a {
    color: #b31b1b; }
    .card-callout__title a:hover {
      color: #e7751d;
      text-decoration: underline; }
      .card-callout__title a:hover:before {
        text-decoration: none; }
    .card-callout__title a:after {
      content: '' !important;
      display: none !important; }
  .card-callout__title a span {
    display: block;
    position: relative;
    top: -40px;
    margin: 0 auto -40px; }
  .card-callout__title a span:before {
    content: '\e802';
    width: 50px;
    height: 50px;
    color: #e7751d;
    background: #f7f7f7;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    line-height: 50px;
    display: inline-block;
    -webkit-transition: background-color 0.25s;
    -o-transition: background-color 0.25s;
    transition: background-color 0.25s; }
  .card-callout__title a.external-link span:before {
    content: '' !important;
    background-image: url("/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 15px 15px;
    margin-left: 6px;
    position: relative;
    top: 2px; }
  .card-callout__title a:hover span:before {
    text-decoration: none; }

.card-callout__description {
  font-size: 15px;
  text-align: center; }

.card-callout__image {
  overflow: hidden; }

.card-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s; }
  .card-callout__image img:hover {
    transform: scale(1.1); }

.card-callout__cta {
  font-size: 14px; }
  .card-callout__cta a {
    border: none; }

.donate-now-panel__instructions {
  text-align: center;
  margin: 20px 0 30px; }

/* =In-Kind Panel
----------------------------------------------------------*/
.in-kind-panel {
  margin: 0 0 30px; }
  .in-kind-panel h3 {
    margin-top: 0; }
  .in-kind-panel .in-kind-panel__image {
    margin: 0 0 20px; }
    @media screen and (min-width: 992px) {
      .in-kind-panel .in-kind-panel__image {
        margin: 0; } }
    .in-kind-panel .in-kind-panel__image img {
      width: 100%;
      height: auto; }
  .in-kind-panel .in-kind-panel__text h3 {
    margin: 0 0 10px; }

/* =Resources Panel
----------------------------------------------------------*/
.covid-resources-panel {
  padding: 0 0 30px;
  background: #f7f7f7; }

/* =COVID Hotline
----------------------------------------------------------*/
.covid-hotline {
  text-align: center;
  padding: 30px 0;
  background: #b31b1b;
  color: #fff; }
  .covid-hotline .covid-hotline__headline {
    font-size: 30px;
    font-family: "1898Sans-Bold", sans-serif; }
  .covid-hotline p {
    font-size: 20px; }

/* =Giving Tuesday webform
----------------------------------------------------------*/
.covid-19-callout-orange a {
  color: #fff;
  display: block;
  text-align: center;
  padding: 30px 0;
  background: #cf4520;
  font-size: 30px;
  font-family: "1898Sans-Bold", sans-serif;
  margin-bottom: 20px; }

.hero-panel-no-color {
  position: relative;
  background: #b31b1b;
  overflow: hidden;
  margin-bottom: 40px; }
  .hero-panel-no-color .hero-panel__image:before {
    background-color: #000000;
    opacity: 0.3;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .hero-panel-no-color .hero-panel__image img {
    width: 100%;
    height: auto; }
  .hero-panel-no-color .hero-panel__headline {
    font-size: 18px;
    font-family: "1898Sans-Bold", sans-serif;
    line-height: 1.2;
    margin-top: 0;
    color: #fff;
    text-align: center;
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 10; }
    @media screen and (max-width: 767px) {
      .hero-panel-no-color .hero-panel__headline {
        padding: 0 30px 0; } }
    @media screen and (max-width: 390px) {
      .hero-panel-no-color .hero-panel__headline {
        font-size: 15px; } }
    @media screen and (max-width: 440px) {
      .hero-panel-no-color .hero-panel__headline {
        padding: 0; } }
    @media screen and (min-width: 768px) {
      .hero-panel-no-color .hero-panel__headline {
        font-size: 30px;
        padding: 0 10% 10px; } }
    @media screen and (min-width: 992px) {
      .hero-panel-no-color .hero-panel__headline {
        font-size: 36px;
        padding: 0 12% 10px; } }
  .hero-panel-no-color .btn-primary {
    margin-top: 10px;
    padding: 6px 40px;
    font-weight: bold; }
    @media screen and (min-width: 768px) {
      .hero-panel-no-color .btn-primary {
        font-size: 20px;
        margin-top: 30px; } }
    @media screen and (min-width: 992px) {
      .hero-panel-no-color .btn-primary {
        margin-top: 40px; } }

.pane-webform-embed-panel-pane-1 {
  margin-bottom: 30px;
  margin-top: 40px; }
  .pane-webform-embed-panel-pane-1 .col-sm-12 {
    margin-top: 20px;
    margin-bottom: 20px; }
    .pane-webform-embed-panel-pane-1 .col-sm-12 h2 {
      text-align: center; }
  .pane-webform-embed-panel-pane-1 .disclaimer, .pane-webform-embed-panel-pane-1 .webform-component--disclaimer {
    font-style: italic; }
  .pane-webform-embed-panel-pane-1 .giving-messages-container {
    background: #f7f7f7;
    border: 2px solid #e7751d;
    padding: 25px;
    height: 730px;
    text-align: center; }
    .pane-webform-embed-panel-pane-1 .giving-messages-container h3 {
      margin-top: 0; }
    .pane-webform-embed-panel-pane-1 .giving-messages-container h4 {
      padding-bottom: 20px;
      margin-top: 5px;
      margin-bottom: 20px;
      border-bottom: 1px solid #dddddd; }
    .pane-webform-embed-panel-pane-1 .giving-messages-container #giving-tuesday-messages {
      height: 85%;
      overflow-y: scroll;
      padding-bottom: 20px;
      word-break: break-word; }
      @media screen and (max-width: 390px) {
        .pane-webform-embed-panel-pane-1 .giving-messages-container #giving-tuesday-messages {
          height: 80%; } }
      .pane-webform-embed-panel-pane-1 .giving-messages-container #giving-tuesday-messages.highlight_response .message_response:first-child {
        background-color: #cf4520;
        color: #fff; }
      .pane-webform-embed-panel-pane-1 .giving-messages-container #giving-tuesday-messages .message_response {
        margin-bottom: 20px; }
        .pane-webform-embed-panel-pane-1 .giving-messages-container #giving-tuesday-messages .message_response .message_text {
          font-weight: 700;
          font-style: italic; }
        .pane-webform-embed-panel-pane-1 .giving-messages-container #giving-tuesday-messages .message_response .message_author {
          font-family: "1898Sans-Regular", sans-serif; }

.view-id-partners_in_medicine {
  /*text-align: center;*/ }

.event-row {
  border-bottom: solid #000 .15em; }
