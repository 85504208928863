$primary-nav-width: 16.6%;

/* =============================================================================
   ~Navigation
   ========================================================================== */

#primary-nav .level-1 {
  width: $primary-nav-width; //Customize me based on content!

    &:last-child a:hover {
        border-bottom: none;
    }

    @include breakpoint($sm) {
        padding: 1em 16px;
    }
}

.global-links__wcm {
    display: none !important;
}

.global-links a {
    color: $wcm-med-gray !important;
    &:hover {
        color: $wcm-med-gray;
    }
}
.global-links>li>a:hover, .global-links>li>a:focus {
    color: $wcm-med-gray;
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;

        li.level-1:nth-child(2) {
            display: inline-block;
        }
        li.level-1:nth-child(3) {
            display: inline-block;
        }
    }
}

.footer-contact__contact-us a {
    @include breakpoint($sm) {
        padding: 20px 20px;
    }
    @include breakpoint($md) {
        padding: 20px 48px;
    }
}

.footer-contact__social-links li {
    @include breakpoint($sm) {
        padding: 0 5px;
    }
    @include breakpoint($md) {
        padding: 0 10px;
    }
}

/* =============================================================================
   ~Header
   ========================================================================== */
/*.brand__nav {
    background-color: inherit;

    .navbar-nav>li>a {
        line-height: 75px;
    }
}

.brand__logo a {
    display: block;
    background: url(../images/WCM-logo.png) no-repeat left;
    width: 210px;
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    @include breakpoint($sm) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    background-size: 210px;
}
.global-links a {
    color: $wcm-med-gray;
    &:hover {
        color: $wcm-med-gray;
    }
}
.global-links>li>a:hover, .global-links>li>a:focus {
    color: $wcm-med-gray;
}*/

/*.search-toggle span {
    border: 2px solid #fff;
    margin-top: 20px;
//    display: block;
//    width: 40px;
//    height: 40px;
//    border-radius: 50%;
//    cursor: pointer;
//    margin: 6px 0 6px 8px;

    &:hover {
        border: 2px solid $wcm-dark-orange;
    }

    .cta-icon-search {
        color: $wcm-dark-orange;
        fill: $wcm-bright-orange;
    }
}
.search-toggle.open span {
    margin-top: 15px;
    border: 2px solid #fff;
    background: #fff;
    &:hover {
        .cta-icon-search {
            fill: $wcm-dark-orange;
        }
    }
}*/

#tagline {
	margin: 0 auto;
	text-align: center;
	display: none;
	@include breakpoint($sm) {
		background: $wcm-red;
		color: #fff;
		font-size: 2.2rem;
		letter-spacing: .65em;
		padding: .4em 0;
		display: block;
    font-weight: normal;
	}
	@include breakpoint($md) {
		background: $wcm-red;
		color: #fff;
		font-size: 2.6rem;
		letter-spacing: .75em;
		padding: .6em 0;
		display: block;
    font-weight: normal;
	}
}

#tagline a {
	color: #fff;
	text-decoration: none;
}

#tagline-mobile {
//	background: $secondary-color;
	color: #fff;
	letter-spacing: .2em;
	padding: .3em 0;
	text-align: center;
	display: block;
	font-size: 1.4rem;
	@include breakpoint($sm) {
		display: none;
	}
	@include breakpoint($md) {
		display: none;
	}

}

#donate-to-weill {
	display: none;
	float: left;
	color: $wcm-red;
	@include breakpoint($sm) {
		font-size: 1.4rem;
		margin-top: .3em;
		display:none;
	}
	@include breakpoint($md) {
		font-size: 1.6rem;
		margin-top: .2em;
		display:block;
	}
	& span {
		display: block;
		margin: .2em .4em 0 0;
		float: left;
	}
}

#donate-button-top {
	float: right;
	padding: .2em 1em .2em .8em;
	border-radius: 4px;
	text-transform: uppercase;
	@include breakpoint($sm) {
		font-size: 1.3rem;
	}
	@include breakpoint($md) {
		font-size: 1.4rem;
	}
	background: $wcm-red;
	color: #fff;
	&:hover {
		background: #fff;
		border: 1px solid $wcm-red;
		color: $wcm-red;
		cursor: pointer;
	}
}

/* =============================================================================
   ~Homepage
   ========================================================================== */
#home-campaign {
    margin: 0 1em;
    margin-bottom: 30px;
	@include breakpoint($sm) {
		width: 660px;
		margin: 0 auto;
		margin-top: 1em;
        margin-bottom: 30px;
	}
	@include breakpoint($md) {
		width: 960px;
//		margin: 0 auto;
//		margin-top: 1em;
	}
}

#home-campaign h3 {
	color: $wcm-red;
	text-transform: capitalize;
	font-size: 1.8rem;
	line-height: 24px;
	@include breakpoint($sm) {
		font-size: 3.0rem;
		text-align: center;
		line-height: 34px;
		font-weight: 400;
	}
	@include breakpoint($md) {
        font-size: 4.0rem;
		text-align: center;
		line-height: 44px;
		font-weight: 400;
	}
}

#home-campaign p {
	/*@include breakpoint($sm) {
		font-size: 1.8rem;
		line-height: 22px;
	}
	@include breakpoint($md) {
		font-size: 1.8rem;
		line-height: 22px;
	}*/
}

#homepage-donate {
	cursor: pointer;
	float: right;
	border-radius: 50%;
	width: 11.06em;
	height: 11.05em;
	border: .7em solid #fff;
	box-shadow: 0 0 7px rgba(0,0,0,.38);
	padding: 2.53em 1.68em 1.01em 1.47em;
	background: $wcm-dark-orange;
  	text-align: center;
//    text-transform: uppercase;
 	margin: 6% 3% 0 0;
	@include breakpoint($sm) {
		width: 15.2em;
		height: 15.2em;
		background: url("/sites/default/files/general/scope-heart.png") no-repeat center 28% $wcm-dark-orange;
		border: .7em solid #fff;
//		box-shadow: 0 0 7px rgba(0,0,0,.38);
		padding: 4.2em 2.25em 1.35em 1.96em;
	  text-align: center;
	  margin: 6% 3% 0 4%;
  }
	@include breakpoint($md) {
//		width: 15.2em;
//		height: 15.2em;
//		background: url("/sites/default/files/general/scope-heart.png") no-repeat center 28% $wcm-red;
		border: 1em solid #fff;
//		box-shadow: 0 0 7px rgba(0,0,0,.38);
//		padding: 4.2em 2.25em 1.35em 1.96em;
//	  text-align: center;
	  margin: 5% 3% 10% 4%;
  }
}

	#homepage-donate:hover {
		@include breakpoint($sm) {
			background: url("/sites/default/files/general/scope-heart.png") no-repeat center 28% $wcm-bright-orange;
		}
		/*@include breakpoint($md) {
			background: url("/sites/default/files/general/scope-heart.png") no-repeat center 28% #952626;
		}*/
	}

	#homepage-donate h3 {
	color: #fff;
		@include breakpoint($sm) {
			font-size: 2.4rem;
			line-height: 28px;
			padding-top: 2em;
		}
		@include breakpoint($md) {
			font-size: 2.4rem;
			line-height: 28px;
			padding-top: 2em;
		}
	}

.front blockquote {
    border: none;
}

.short-quote {
//	background: url("/sites/default/files/general/quote-small.jpg") no-repeat 34% 21%;
	background-size: 100% 100%;
	@include breakpoint($sm) {
//		background: url("/sites/default/files/general/quotes.jpg") no-repeat 34% 21%;
		margin: 0 auto;
		width: 660px;
	}
	@include breakpoint($md) {
//		background: url("/sites/default/files/general/quotes.jpg") no-repeat 34% 21%;
		margin: 0 auto;
		width: 960px;
	}
}

	.short-quote blockquote {
		margin: 0;
		padding: 1em 0 0 0;
	}

	.short-quote blockquote h2 {
		font-weight: 400;
		margin: 0 1em;
        position: relative;
		color: $wcm-black;
		text-align: center;
		line-height: 1.5em;
		font-size: 1.7rem;
		@include breakpoint($sm) {
			font-size: 1.7rem;
			margin: 0;
		}
		@include breakpoint($md) {
			font-size: 2.2rem;
			margin: 0;
		}

        &:before {
            content: "";
            background-size: 100%;
            position: absolute;

            background: url(../images/quote-small-left.jpg) no-repeat;
            width: 28px;
            height: 28px;
            margin-left: -28px;
            top: -5px;

            @include breakpoint($sm) {
                background: url(../images/quote-left.jpg) no-repeat;
                width: 55px;
                height: 55px;
                margin-left: -55px;
                top: -10px;
            }
        }
	}

	.short-quote p {
		font-size: 1.6rem;
		padding: 0 1em;
		text-align: center;
		@include breakpoint($sm) {
			font-size: 1.6rem;
			padding: 0;
		}
		@include breakpoint($md) {
			font-size: 1.8rem;
			padding: 0;
		}
	}

	.short-quote span {
		color: $wcm-red;
        position: relative;
		display: block;
		margin-top: .5em;
		@include breakpoint($sm) {
			font-size: 2.3rem;
			line-height: 1.3em;
		}
		@include breakpoint($md) {
			font-size: 2.8rem;
			line-height: 1.4em;
		}


        &:after {
            content: "";
            background-size: 100%;
            position: absolute;

            background: url(../images/quote-small-right.jpg) no-repeat;
            width: 28px;
            height: 28px;
//            margin-left: -28px;
            top: auto; // 25px;

            @include breakpoint( (min: 321px) ) {
                top: 25px;
            }

            @include breakpoint($sm) {
                background: url(../images/quote-right.jpg) no-repeat;
                width: 55px;
                height: 55px;
//                margin-left: -55px;
//                top: 25px;
            }
        }
	}

	 .short-quote p.quote-action {
		display: block !important;
	    font-size: 2.0rem;
	    padding: 0.55em 2.5em;
	    text-transform: uppercase;
	    width: 36%;
	    margin: 0 auto .75em auto;
	}

/* =============================================================================
   ~Interior
   ========================================================================== */
.page-priorityareas #main {
	max-width: none;
	margin: 0;
	padding: 0;
}

.priorities-listing {
	margin: 0 1em;
	@include breakpoint($sm) {
		width: 660px;
		margin: 0 auto;
	}
	@include breakpoint($md) {
		width: 960px;
		margin: 0 auto;
	}
}


#priorities-listing-content .views-row,
.view-ways-to-give .views-row,
.view-leadership .views-row {
    @include clearfix();
    border-bottom: 1px solid $wcm-border-gray;
    padding: 20px 0px 11px;
	/*@include breakpoint($sm) {
		border-bottom: 1px solid $wcm-border-gray;
        padding: 20px 0px 11px;
	}*/
  h2 { margin-top: 0px; }
}

.view-priority-areas p { font-size: 1.8rem; }

.view-milestones .view-content {
	text-align: center;
	@include breakpoint($sm) {
		text-align: left;
	}
	@include breakpoint($md) {
		text-align: left;
	}
}


#priorities-listing-content .views-field-field-featured-image,
.view-ways-to-give .views-field-field-featured-image,
.view-leadership .views-field-field-featured-image,
.view-milestones img {
//	float: none;
	text-align: center;
    margin-bottom: 0.5em;
	@include breakpoint($sm) {
		margin-right: 1em;
        margin-bottom: 0;
		float: left;
		text-align:left;
	}
	/*@include breakpoint($md) {
		margin-right: 1em;
		float: left;
		text-align:left;
	}*/
}

.view-milestones .milestonethumb-blank {
    display: none;
    @include breakpoint($sm) {
        display: block;
    }
}

.view-milestones img {
	width: 180px;
	border-radius: 0;
}

#priorities-listing-content a > img,
.view-ways-to-give a > img,
.view-leadership a > img {
		border-radius: 0;
		border: 4px solid #ebebeb;
}


#priorities-listing-content h2.field-content,
.view-milestones h2.field-content {
	font-weight: 400;
	margin-bottom: .4em;
	text-align: center;
	font-size: 2.4rem;
	@include breakpoint($sm) {
        font-size: 2.8rem;
		text-align:left;
	}
	@include breakpoint($md) {
		font-size:2.8rem;
		text-align:left;
	}
}

.view-milestones .views-field-body {
	text-align: left;
	float: right;
}

#priorities-listing-content .field-content {
//	font-size: 2.0rem;
}

.view-milestones .field-content {
	font-size: 1.8rem;
}

#panels-ipe-regionid-sidebar {
	margin-top: 2em;
	@include breakpoint($sm) {
		margin-top: 0;
	}
	@include breakpoint($md) {
		margin-top: 0;
	}
}

.pane-node-body iframe {
    max-width: 640px;
    width: 100%;
}

.button {
  color: #fff;
  display: block;
  padding: .55em 2.5em .55em 3.5em;
  margin-bottom: .5em;
  text-align: center;
  cursor: pointer;
  &, a{
    color: #fff;
    text-decoration: none;
  }
  &:hover{
    background: #00355f;
  }
  @include breakpoint($sm) {
    font-size: 1.8rem;
  }
  @include breakpoint($md) {
    font-size: 2.0rem;
  }
}

.panel-twocol #information-sidebar {
    @include breakpoint($sm) {
    	font-size: 1.6rem;
    }
}

.donate-now {
	background: url("/sites/default/files/general/scope-heart-sm.png") no-repeat 13% center $wcm-dark-orange;
	&:hover{
    background: url("/sites/default/files/general/scope-heart-sm.png") no-repeat 13% center $wcm-bright-orange;
  }
}

.contact-us {
	background: url("/sites/default/files/general/mail-icon.png") no-repeat 13% center $wcm-dark-orange;
	&:hover {
    background: url("/sites/default/files/general/mail-icon.png") no-repeat 13% center $wcm-bright-orange;
  }
}

figure.hero-image,
.field-name-field-thumbnail-image {
    position: relative;
}

.hero-image img,
.field-name-field-thumbnail-image img {
	border: 1px solid #d8d2c9;
}

.hero-image figcaption {
    line-height: 1.2em;
    margin-top: 0.7em;
    font-size: 1.4rem;
}

.page-milestones h1 {
    margin: 20px 0;
}
.page-milestones .hero-image,
.page-milestones .field-name-field-thumbnail-image {
	@include breakpoint($sm) {
		float: right;
	}
	@include breakpoint($md) {
		float: right;
	}
}

.page-milestones .medium {
	border-radius: 0;
	border: 1px solid #d8d2c9;
	margin: 0 0 1em;
	@include breakpoint($sm) {
		margin: 1.5em 0 2.5em;
	}
	@include breakpoint($md) {
		margin: 1.5em 0 2.5em;
	}
}

.view-milestones .views-row {
	border-bottom: 1px solid $wcm-border-gray;
	margin-top: 1em;
    padding-bottom: 1em;
}

.page-milestones .views-row-first {
	margin-top: 0;
}

.page-milestones .views-row-last {
	border-bottom: 0;
}

#panels-ipe-regionid-header {
	@include breakpoint($sm) {
		height: 300px;
		position:relative;
	}
	@include breakpoint($md) {
		height: 332px;
		position:relative;
	}
}


#panels-ipe-paneid-87,
.pane-fpid-32 {
	margin: 0 1em;
	@include breakpoint($sm) {
		width: 660px;
		margin: 0 auto;
	}
	@include breakpoint($md) {
		margin: 0 auto;
		width: 960px;
	}
}

#panels-ipe-paneid-58 table,
#panels-ipe-paneid-58 th,
#panels-ipe-paneid-58 tr,
#panels-ipe-paneid-58 td, {
	width: 100%;
	vertical-align: top;
}

#panels-ipe-paneid-58 td {
	padding: .5em;
	width: 32%;
}

.pane-bundle-table {
  font-size: 1.5rem;
  .pane-title {
    font-size: 1.8rem;
  }
  tr.even {
    background: none;
  }
  tr.odd {
    background: none;
  }
  td {
    padding: .5em .25em;
  }
}

/* =============================================================================
   ~Components
   ========================================================================== */
/*.title-bar {
    font-family: $wcm-regular;
    display: block;
    font-size: 24px;
//    padding-top: 5px;
    @include breakpoint($sm) {
        font-size: 46px;
//        padding-top: 10px;
//        line-height: 1.8;
    }

    .site-title {
        display: block;
        padding-top: 5px;
        @include breakpoint($sm) {
            padding-top: 10px;
        }
    }
}*/
.contact-info .site-name {
    display: none;
}
.milestone-story-dates {
	@include breakpoint($md) {
		font-size: 1.6rem;
	}
}

.view-milestones h3.pane-title,
.view-milestones .panel-separator {
	display: none;
}

.view-milestones h3 {
	margin: 0 0 .2em;
	font-weight: 400;

    a:after {
        font-size: 12px;
        color: $wcm-bright-orange;
        content: '\e80D';
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: none;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1.8em;
        margin-left: .2em;
        vertical-align: top;
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

h2 a {
    color: $wcm-red;
    &:hover {
        color: $wcm-bright-orange;
    }
    &:after {
    font-size: 16px;
    color: $wcm-bright-orange;
    content: '\e80D';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: none;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1.8em;
    margin-left: .2em;
    vertical-align: top;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
}
h3 a {
    color: $wcm-red;
    &:hover {
        color: $wcm-bright-orange;
    }
    &:after {
    font-size: 12px;
    color: $wcm-bright-orange;
    content: '\e80D';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: none;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1.8em;
    margin-left: .2em;
    vertical-align: top;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
}

.ctools-collapsible-processed .ctools-collapsible-handle h2 {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: #cf4520;
    border: 1px solid #ddd;
    border-width: 3px 0 0;
    cursor: pointer;
    margin: 0;
    padding: 15px 55px 15px 10px;
    font-weight: 600;
}

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
    content: '-';
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 3px solid #ddd;
}

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
    content: '+';
}

.ctools-collapsible-container .ctools-toggle {display:none;}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

.wcm-cta {
  .wcm-cta__tile {
    &:nth-child(3) {
      width: 100%;
      border-width: 1px 0 0 0;

      &:hover {
        border-width: 1px;
      }
    }
  }
}
